<template>
	<div class="mt-10 fade">
	  <v-list dense class="drawer">
		<section v-for="(item, i) in lists" :key="i">
		  <section v-for="(list, j) in item.lists" :key="j">
			<v-list-item v-if="j==1 && list.route == 'User My Courses' && !$vuetify.breakpoint.mobile" 
				 class="px-5"
				:class="($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_link_color--text':'' "
				:style="mini ? (($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? {  background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColorMini}` } : '') : (($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? {  background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor} !important`} : '')"
				@click="collapse()"
			  >
				  <v-list-item-content>
					  <v-list-item-title class="roboto f14 d-flex align-center "
					  :class="[($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_link_color--text' : 'secondary-2--text', mini && 'justify-center']" >
						  <v-icon v-if="mini" class="px-1" size="20" :color="($route.name === 'User Course Details')  ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">
							  mdi-book-open-outline
						  </v-icon>
						  <v-icon v-else class="px-1" size="20" :class="($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_link_color--text' : 'secondary-2--text'">mdi-book-open-outline</v-icon>
						  <div v-if="!mini" class="mx-3 fw500 ">My Courses</div>
					  </v-list-item-title>
				  </v-list-item-content>
				  
			  </v-list-item>
			  <!-- <v-list-group v-else-if="j==1 && list.route == 'User My Courses' && $vuetify.breakpoint.mobile" 
				  	class="px-1" 
				  	:class="($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_link_color--text':'' "
					:style="mini ? (($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? {  color: getlinkColor, borderRight: `5px solid ${getlinkColorMini}` } : '') : (($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? {  color: getlinkColor, borderRight: `5px solid ${getlinkColor} !important`} : '')"
				>
				  <template v-slot:activator>
					  <v-list-item-content>
						  <v-list-item-title class="roboto f14 d-flex align-center" >
							  <v-icon class="px-1" size="20" 
								  :class="$route.name !== 'User Course Details' ? 'secondary-2--text' : 'navigation_link_color--text'"> mdi-book-open-outline </v-icon>
							  <div class="px-3" 
								  :class="$route.name !== 'User Course Details' ? 'secondary-2--text' : 'navigation_link_color--text'"> My Courses </div>
						  </v-list-item-title>
					  </v-list-item-content>
				  </template>
				   <section>
					<v-progress-linear
						indeterminate
						color="white"
						rounded
						height="3px"
						v-if="loading"
						class="px-3"
						></v-progress-linear>

						<v-list v-if="courses.length>0">
							<v-list-item dense class="pl-12" 
								v-for="item in courses" 
								:key="item.id"
								@click="() => {
									collapse()
									$router.push({name: 'User Course Details', params: {id: item.course.uuid}})
								}"
								:style="$route.path === `/course/${item.course.uuid}` && { background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor} !important`}"
							>
								<v-list-item-content>
									<v-list-item-title class="roboto fw500 f14 d-flex align-center text-wrap"
										:class="$route.path === `/course/${item.course.uuid}` ? 'navigation_link_color--text' : 'secondary-2--text'">
										{{item.course.title}}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<div v-if="courses.length === 0 && !loading" class="pa-5 roboto fw500 f14 d-flex align-center">No courses found.</div>
				   </section>
			  </v-list-group> -->
			  <v-list-item 
					v-if="list.route !== 'User List' && j!=1"
					:to="{ name: list.route }"
					class="px-5" 
					:class="($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_link_color--text':'' "
					:style="mini ? (($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? {  background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColorMini}` } : '') : (($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? {  background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor} !important`} : '')"
				>
				  <v-list-item-content>
					  <v-badge
						  overlap
						  :inline="!mini"
						  bordered
						  :color="cart.length === 0 ? 'transparent' : 'primary'"
						  :content="cart.length"
						  :offset-x="!mini ? 25 : 15"
						  :style="text"
						  :dot="mini"
						  v-if="list.route === 'User List'">
						  <v-list-item-title class="roboto f14 d-flex align-center"
							  :class="[mini && 'justify-center', ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">
							  <v-icon v-if="mini" class="px-1" size="20" :color="($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">{{list.icon}}</v-icon>
							  <v-icon v-else class="px-1" size="20" :class="[($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">{{list.icon}}</v-icon>
							  <div v-if="!mini" class="mx-3 fw500 ">{{list.title}}</div>
						  </v-list-item-title>
					  </v-badge>
					  <v-list-item-title class="roboto f14 d-flex align-center" v-else
						  :class="[mini && 'justify-center', ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">
						  <v-icon v-if="mini" class="px-1" size="20" :color="($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">{{list.icon}}</v-icon>
						  <v-icon v-else class="px-1" size="20" :class="[($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">{{list.icon}}</v-icon>
						  <div v-if="!mini" class="mx-3 fw500 ">{{list.title}}</div>
					  </v-list-item-title>
				  </v-list-item-content>
			  </v-list-item>
			  <v-list-item 
					v-else-if="j!=1"
					:to="{ name: list.route }"
					exact
					class="px-5 li" 
					:style="!mini ? [($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) 
								? { background: getlinkColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor}`}: ''] 
							: [($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? { background: getlinkColorMini, color: getlinkColorMini, borderRight: `5px solid ${getlinkColorMini}` } : '']"
					:class="!mini ? [($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details'))? 'navigation_link_color--text' :  'navigation_link_inactive_color--text'] : [($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'mini_navigation_link_color--text' :  'mini_navigation_inactive_link_color--text']"
				>
				  <v-list-item-content>
					  <v-badge
						  overlap
						  :inline="!mini"
						  bordered
						  :color="cart.length === 0 ? 'transparent' : 'primary'"
						  :content="cart.length"
						  :offset-x="!mini ? 25 : 15"
						  :style="text"
						  :dot="mini"
						  v-if="list.route === 'User List' && cart.length > 0">
						  <v-list-item-title class="roboto f14 d-flex align-center"
							  :class="[mini && 'justify-center', ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">
							  <v-icon v-if="mini" class="px-1" size="20" :color="($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">{{list.icon}}</v-icon>
							  <v-icon v-else class="px-1" size="20" :class="[($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">{{list.icon}}</v-icon>
							  <div v-if="!mini" class="mx-3 fw500 ">{{list.title}}</div>
						  </v-list-item-title>
					  </v-badge>
					  <v-list-item-title class="roboto f14 d-flex align-center" v-else
						  :class="[mini && 'justify-center', ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">
						  <v-icon v-if="mini" class="px-1" size="20" :color="($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'mini_navigation_icon_color' : 'mini_navigation_inactive_link_color'">{{list.icon}}</v-icon>
						  <v-icon v-else class="px-1" size="20" :class="[($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : ($route.meta.parent === list.route || (prevRoute === list.route && $route.meta.parent === 'User Other Course Details')) ? 'navigation_text_icon_color--text' : 'navigation_link_inactive_color--text']">{{list.icon}}</v-icon>
						  <div v-if="!mini" class="mx-3 fw500 ">{{list.title}}</div>
					  </v-list-item-title>
				  </v-list-item-content>
			  </v-list-item>
			  
		  </section>
		  
		</section>
	  </v-list>
	</div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  
  export default {
	props: ['lists', 'mini'],
	data: () => ({
		prevRoute: null
	}),
	methods: {
		collapse() {
			if(this.$route.meta.parent == 'User Assessment') return
			this.$emit('collapse', true)
		}
	},

	watch: {
		$route (to, from){
			if(to.name === 'User Course Details' && from.name === 'User Course Details') {
				this.$emit('collapse', true)
			} else this.$emit('collapse', false)

			if(from.name != 'User Other Course Details') {
				this.prevRoute = from.name
			}
			
		},
	},
	computed: {
		...mapState({
			customization: (state) => state.customization,
			tenant: (state) => state.tenant,
		}),

		...mapState("usr", {
			cart: (state) => state.cart
		}),

		getlinkColor(){
		return this.customization.hasOwnProperty('navigation_text_icon_color') ? this.customization.navigation_text_icon_color ? this.customization.navigation_text_icon_color : '#3966D8' : '#3966D8'
		},
	
		getlinkColorMini(){
		return this.customization.hasOwnProperty('mini_navigation_link_color') ? this.customization.mini_navigation_link_color ? this.customization.mini_navigation_link_color : '#3966D8' : '#3966D8'
		},

		getlinkBackgroundColor(){
		return this.customization.hasOwnProperty('mini_navigation_link_color') ? this.customization.mini_navigation_link_color ? this.customization.mini_navigation_link_color.concat('33') : '#3966D833' : '#3966D833'
		},

		getColorByBgColorMini() {
		if(this.customization.mini_navigation_background_color === '#3966D8') return 'secondary-2--text'
			return (parseInt(this.customization.mini_navigation_background_color.replace('#', ''), 16) > 0xffffff / 2) ? 'secondary-2' : 'grey';
		}
	},
  }
  </script>
<template>
  <v-navigation-drawer class="drawer hidden-md-and-down"
    app
    v-model="drawer"
    disable-route-watcher 
    style="margin-left: 80px" 
    stateless
    disable-resize-watcher
    permanent
    fixed
    :width="setWidth"
  >
    <h3 class="poppins fw600 mx-7 mb-3" style="margin-top: 120px"> 
      My Courses
    </h3>
    <v-progress-linear
      indeterminate
      color="primary"
      rounded
      height="3px"
      v-if="loading"
      class="px-3"
      ></v-progress-linear>
    <v-list v-if="courses.length>0">
      <v-list-item dense class="px-7" 
        v-for="item in courses" 
        :key="item.id"
        exact
        exact-path
        @click="() => {
          $store.commit('drawerMutation', false)
          $router.push({name: 'User Course Details', params: {id: item.course.uuid}})
        }"
        :style="($route.path === `/course/${item.course.uuid}` || $route.path === `/course/${item.course.uuid}/content`) && { background: getlinkBackgroundColor, color: getlinkColor, borderRight: `5px solid ${getlinkColor}`}"
      >
        <v-list-item-content>
          <v-list-item-title class="roboto fw500 f14 d-flex align-center truncate" 
          :class="($route.path === `/course/${item.course.uuid}` || $route.path === `/course/${item.course.uuid}/content`) ? 'navigation_link_color--text' : 'navigation_link_inactive_color--text'">
            {{item.course.title}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-if="courses.length === 0 && !loading" class="px-7 roboto fw500 f14 d-flex align-center">No courses found.</div>
    
    <v-pagination
        dense
        :value="pagination.page"
        :length="pagination.pageCount"
        :total-visible="5"
        color="primary"
        flat
        elevation="0"
        class="f10 my-2 px-7"
        @input="(e) => { paginationMutation({ ...pagination, page: e}), getEnrolledCourses() }"
    >
    </v-pagination>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    drawer: true,
    loading: false,
  }),
  mounted() {
    if(this.$vuetify.breakpoint.mobile) {
      this.getEnrolledCourses()
    } else if(this.courses.length === 0 || this.pagination.paginate !== 10) {
      this.getEnrolledCourses()
    }
  },
  methods:{
    ...mapActions('usr', ['getEnrolledCoursesAction']),
    
    ...mapMutations('usr', ['paginationMutation']),

    getEnrolledCourses(){
      if(this.$vuetify.breakpoint.mobile) {
        this.loading = true
        this.getEnrolledCoursesAction().finally(() => {
          this.loading = false
        })
      } else {
        this.loading = true
        this.getEnrolledCoursesAction({...this.pagination, paginate: 10}).finally(() => {
          this.loading = false
        })
      }
    },
  },
  computed: {
    ...mapState({
      customization: (state) => state.customization
    }),

    ...mapState('usr', {
      courses: (state) => state.courses,
      pagination: (state) => state.pagination,
    }),

    setWidth(){
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': 
          case 'sm': 
          case 'md': return 0
          case 'lg': 
          case 'xl': return 230
        }
    },

    getlinkColor(){
			return this.customization.hasOwnProperty('mini_navigation_link_color') ? this.customization.mini_navigation_link_color ? this.customization.mini_navigation_link_color : '#3966D8' : '#3966D8'
		},

		getlinkBackgroundColor(){
			return this.customization.hasOwnProperty('mini_navigation_link_color') ? this.customization.mini_navigation_link_color ? this.customization.mini_navigation_link_color.concat('33') : '#3966D833' : '#3966D833'
		},
  }
}
</script>
<template>
  <section>
    <v-divider v-if="i != 0" />
    <div class="mr-3 my-5">
      <div class="text-right">
        <v-btn @click="$emit('removeInput', i)" text small color="danger-1" class="text-capitalize poppins f12 fw500">
          <v-icon left >mdi-close</v-icon>
          Delete
        </v-btn>
      </div>
      <v-row>
        
        <v-col cols="12">
          <label class="poppins fw500 f12">FIRST NAME* </label>
          <v-text-field v-model="input.first_name" outlined dense hide-details="auto" :error-messages="error && error.first_name"  
            class="general-custom-field roboto f14 secondary-1--text fw500"/>
        </v-col>
        
        <v-col cols="12">
          <label class="poppins fw500 f12">MIDDLE NAME</label>
          <v-text-field v-model="input.middle_name" outlined dense hide-details="auto" :error-messages="error && error.middle_name"  
            class="general-custom-field roboto f14 secondary-1--text fw500"/>
        </v-col>

        <v-col cols="12">
          <label class="poppins fw500 f12">LAST NAME* </label>
          <v-text-field v-model="input.last_name" outlined dense hide-details="auto" :error-messages="error && error.last_name"
            class="general-custom-field roboto f14 secondary-1--text fw500"/>
        </v-col>

        <v-col cols="12">
          <label class="poppins fw500 f12">SUFFIX</label>
          <v-text-field v-model="input.suffix" outlined dense hide-details="auto" :error-messages="error && error.suffix"  
            class="general-custom-field roboto f14 secondary-1--text fw500"/>
        </v-col>

        <v-col cols="12">
          <label class="poppins fw500 f12">USER TYPE</label>
          <v-select v-model="input.role" :items="items" outlined dense hide-details="auto" :error-messages="error && error.role" 
            class="general-custom-field roboto f14 secondary-1--text fw500 text-capitalize"/>
        </v-col>
          <v-col cols="12">
          <label class="poppins fw500 f12">EMAIL ADDRESS* </label>
          <v-text-field v-model="input.email" outlined dense hide-details="auto" type="email" :error-messages="error && error.email"
            class="general-custom-field roboto f14 secondary-1--text fw500"/>
        </v-col>
        <!-- <v-col cols="12" v-if="input.role === 'user'">
          <section v-if="tenant === 'general_education'">
            <label class="poppins fw500 f12">CLASS CATEGORIES</label>
            <v-select 
              outlined 
              dense 
              class="general-custom-field roboto f12 secondary-1--text fw500"
              :items="classes"
              v-model="input.class_category_id"
              item-value="id"
              hide-details="auto"
              :error-messages="error && error.class_category_id"
              multiple
            >
              <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="roboto f14 fw500">
                      <span class="secondary--text">{{item.class_code}}-</span>
                      <span class="secondary-3--text">{{item.class_name}}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template slot="selection"  slot-scope="data">
                <div class="roboto f14 fw500 my-1">
                  <span class="secondary--text">{{data.item.class_code}}-</span>
                  <span class="secondary-3--text">{{data.item.class_name}} </span>
                </div>
              </template>
            </v-select>
            <section class="mt-3">
              <label class="poppins fw500 f12">COURSES</label>
              <v-select 
                outlined 
                dense 
                class="general-custom-field roboto f12 secondary-1--text fw500"
                :items="class_courses"
                v-model="input.course_id"
                item-text="title"
                item-value="id"
                hide-details="auto"
                :error-messages="error && error.course_id"
                multiple
              >
              </v-select>
            </section>
          </section>
        </v-col> -->
      </v-row>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ManualInput',
  props: ['i', 'input', 'error'],
  data: () => ({
    items: ['user', 'instructor']
  }),
  computed: {
    // ...mapState('admin', {
    //   classes: (state) => state.classes
    // })

    ...mapState('admin', {
      //courses: (state) => state.courses,
      classes: (state) => state.classes
    }),

    ...mapState({
      tenant: (state) => state.tenant
    }),

    class_courses(){
      let _courses = []
      this.input.class_category_id.forEach((_class)=>{
        _courses.push(...this.classes.filter(item => item.id === _class)[0].courses)
      })

      return _courses
    }
  }
}
</script>

<template>
    <section>
        <section>
            <v-icon v-if="isValidLength && notEmpty" color="success" small>
                mdi-check-circle
            </v-icon>
            <v-icon v-else color="error" small>
                mdi-close-circle
            </v-icon>
            <span class="f11 ml-2">Password must be at least 8 characters</span>
        </section>
        <section>
            <v-icon v-if="hasUppercase && notEmpty" color="success" small>
                mdi-check-circle
            </v-icon>
            <v-icon v-else color="error" small>
                mdi-close-circle
            </v-icon>
            <span class="f11 ml-2">Password must have at least one uppercase letter</span>
        </section>
        <section>
            <v-icon v-if="hasLowercase && notEmpty" color="success" small>
                mdi-check-circle
            </v-icon>
            <v-icon v-else color="error" small>
                mdi-close-circle
            </v-icon>
            <span class="f11 ml-2">Password must have at least one lowercase letter</span>
        </section>
        <section>
            <v-icon v-if="hasNumber && notEmpty" color="success" small>
                mdi-check-circle
            </v-icon>
            <v-icon v-else color="error" small>
                mdi-close-circle
            </v-icon>
            <span class="f11 ml-2">Password must have at least one number</span>
        </section>
        <section>
            <v-icon v-if="hasSymbol && notEmpty" color="success" small>
                mdi-check-circle
            </v-icon>
            <v-icon v-else color="error" small>
                mdi-close-circle
            </v-icon>
            <span class="f11 ml-2">Password must have at least one symbol ( #, ?, !, @, $, %, ^, &, *, _, - )</span>
        </section>
    </section>
</template>

<script>
export default {
    props: {
        password: {
            type: String,
            default: ''
        }
    },
    computed: {
        notEmpty() {
            return this.password && this.password.trim().length > 0;
        },
        isValidLength() {
            return /^.{8,}$/.test(this.password);
        },
        hasUppercase() {
            return /[A-Z]/.test(this.password);
        },
        hasLowercase() {
            return /[a-z]/.test(this.password);
        },
        hasNumber() {
            return /[0-9]/.test(this.password);
        },
        hasSymbol() {
            return /[#?!@$%^&*_-]/.test(this.password);
        }
    }
}
</script>

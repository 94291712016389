<template>
    <v-card tile max-height="400">
        
        <v-card-title class="fixed poppins f16 fw600 pb-0">
            My List
        </v-card-title>
        
        <v-container class="d-flex flex-column text-center pa-10" v-if="cart.length === 0">
            <span class="f12">Your list is empty</span>
        </v-container>
        <v-list width="310" height="180" dense style="overflow-y: auto;" v-if="cart.length > 0">
            <v-list-item v-for="item in cart" :key="item.uuid" class="d-flex flex-row my-2">
                <v-img
                    max-width="100"
                    class="mr-1 cursor-pointer"
                    :src="item.image ? item.image.url : require('../../../assets/images/default-course-bg.png')"
                    :lazy-src="item.image ? item.image.url : require('../../../assets/images/default-course-bg.png')"
                    v-on:error="item.image = null"
                    @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})"/>
                <v-container class="d-flex flex-column cursor-pointer py-0 px-0" @click="$router.push({name: 'User Other Course Details', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})">
                    <section class="poppins fw600 ">
                        <div class="truncated">
                            {{ truncateDescription(item.title, 18) }}
                        </div>
                    </section>
                    <!-- <section class="d-flex flex-row align-center f12">
                        <section class="d-flex flex-row">
                            <v-avatar size="15" color="white" style="position:relative">
                                <v-img 
                                    :src="require('../../../assets/images/authors/mingyu.png')"
                                    :lazy-src="require('../../../assets/images/authors/mingyu.png')"/>
                            </v-avatar>
                            <v-avatar size="15" color="white" style="position:relative; z-index: 2; left: -5px">
                                <v-img 
                                    :src="require('../../../assets/images/authors/gongyoo.png')"
                                    :lazy-src="require('../../../assets/images/authors/gongyoo.png')"/>
                            </v-avatar>
                            <v-avatar size="15" color="white" style="position:relative; z-index: 3; left: -10px">
                                <v-img 
                                    :src="require('../../../assets/images/authors/yoon.png')"
                                    :lazy-src="require('../../../assets/images/authors/yoon.png')"/>
                            </v-avatar>
                        </section>
                        {{ truncateDescription('Ms. Maria Desiree S. Bacsa-Mallo, Mr, John Doe, Ms. Belle Cruz', 20) }}
                    </section> -->
                    
                    <span class="primary--text f14 fw600" v-if="item.is_free">FREE!</span>
                    <span class="primary--text f14 fw600" v-if="!item.is_free">PHP {{ item.price ? item.price : 0 }}</span>
                </v-container>
                <v-btn icon dense @click="removeFromList(item.uuid, item.id)" size="10">
                    <v-icon> mdi-close </v-icon>
                </v-btn>
            </v-list-item>
        </v-list>
        
        <v-card-title class="fixed poppins poppins f16 fw600 pb-0 text-right" v-if="cart.length > 0">
            Total: Php {{ getPrice }}
        </v-card-title>

        <v-card-title class="d-flex flex-column" v-if="cart.length > 0">
            <v-btn
                outlined
                color="primary"
                class=" f11 poppins mb-2"
                dense
                block
                @click="$router.push({name: 'User List'})">
                Go to List
            </v-btn>
        </v-card-title>
    </v-card>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'

export default {
    name: 'cart-menu',
    computed: {
        ...mapState('usr', {
            cart: (state) => state.cart
        }),

        getPrice() {
            if(this.cart.length === 0) {
                return 0
            } else {
                let price = 0
                this.cart.forEach(i => {
                    if(!i.is_free) {
                        price += parseInt(i.price ? i.price : 0)
                    }
                })
                return price
            }
        }
    },
    methods: {
        ...mapMutations('usr', ['cartMutation']),
        ...mapActions('usr', ['removeFromCart', 'getCartItems']),

        truncateDescription(desc, length) {
            return desc.length > length ? `${desc.slice(0, length-4)}...` : desc
        },

        removeFromList(uuid, id){
            let filtered = this.cart.filter(i => i.uuid !== uuid)
            this.cartMutation(filtered)
            if(filtered.length === 0) {
                localStorage.removeItem('cart')
            } else {
                localStorage.setItem('cart', JSON.stringify(filtered))
            }
            this.removeFromCart({course_id: id})
        }
    },
    mounted() {
        // if(JSON.parse(localStorage.getItem('cart'))) {
        //     let _cart = JSON.parse(localStorage.getItem('cart'))
        //     this.cartMutation(_cart)
        // }
    },
}
</script>